import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Badge, List, Space, Spin} from '@arco-design/web-react';
import {getWxUsersApi} from "../../../api/normalApi";
import UserChat from "./UserChat";
import { getThreadMessages} from "../../../api/openapi";

const ChatList = ({currentWxAccount}) => {
    const [chatList, setChatList] = useState([]);  // 存储用户数据
    const [loading, setLoading] = useState(true);  // 加载状态
    const [messages, setMessages] = useState([]);  // 当前聊天的消息
    const [imageUrls, setImageUrls] = useState({}); // 存储图片 URL
    const currentWxUser = useRef(); // 当前选中的用户
    const [lastMessages, setLastMessages] = useState({}); // 存储每个用户的最后一条消息
    const wxUserUnreadList = useRef([])

    // 定义一个截断文本的函数
    const truncateText = (text, maxLength) => {
        if (!text) return '';
        return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    };

    // 获取特定线程的消息
    const getMessageList = async (threadId) => {
        try {
            return await getThreadMessages(threadId);
        } catch (e) {
            console.error(`Error fetching messages for thread_id ${threadId}:`, e);
            return null;
        }
    };

    //找到未读的第一条消息
    const findFirstReadTimeMessage = (messages) => {
        for (let i = 0; i < messages.length; i++) {
            if (messages[i].metadata && messages[i].metadata.readTime) {
                return i;  // 返回第一个匹配的索引
            }
        }
        return -1;  // 如果没有找到，返回 -1
    }

    // 获取用户及其最后一条消息
    const fetchUsers = async () => {
        try {
            const response = await getWxUsersApi(String(currentWxAccount.wx_user_id), true);
            if (response.status === 200) {
                const users = response.data;
                setChatList(users);

                // 获取所有用户的最后一条消息
                const fetchLastMessages = async () => {
                    try {
                        const promises = users.map(user => getMessageList(user.thread_id));
                        const results = await Promise.all(promises);
                        const newLastMessages = {};
                        let unReadMessageCount = [];
                        results.forEach((result, index) => {
                            const user = users[index];
                            unReadMessageCount.push(findFirstReadTimeMessage(result.data))
                            if (result && result.data && result.data.length > 0) {
                                // 假设最新的消息在数组的第一个元素
                                const lastMessageObj = result.data[0];
                                // 检查 content 数组是否存在且有元素
                                if (lastMessageObj.content && lastMessageObj.content.length > 0) {
                                    // 查找包含 text 的对象
                                    const textObj = lastMessageObj.content.find(item => item.text);
                                    if (textObj && textObj.text && textObj.text.value) {
                                        newLastMessages[user.thread_id] = textObj.text.value;
                                    } else {
                                        newLastMessages[user.thread_id] = '[无效消息格式]';
                                    }
                                } else {
                                    newLastMessages[user.thread_id] = '[无消息内容]';
                                }
                            } else {
                                newLastMessages[user.thread_id] = '暂无消息';
                            }
                        });
                        wxUserUnreadList.current = unReadMessageCount;
                        setLastMessages(newLastMessages);
                    } catch (error) {
                        console.error("Error fetching last messages:", error);
                    }
                };

                await fetchLastMessages();

                // 如果没有选中的用户，设置第一个用户为默认
                if (users.length > 0 && !currentWxUser.current) {
                    const firstUser = users[0];
                    currentWxUser.current = firstUser;
                    const data = await getMessageList(firstUser.thread_id);
                    if (data && data.data) {
                        setMessages(data.data);
                    }
                }
            }
        } catch (error) {
            console.error("API Error:", error);
        } finally {
            setLoading(false);  // 结束加载状态
        }
    };

    // 每 10 秒获取一次用户列表
    useEffect(() => {
        if (currentWxAccount) {
            fetchUsers();
        }

        const intervalId = setInterval(() => {
            if (currentWxAccount) {
                fetchUsers();
            }
        }, 10000);

        return () => clearInterval(intervalId);
    }, [currentWxAccount]);

    // 每 5 秒获取当前用户的消息
    useEffect(() => {
        if (currentWxUser.current) {
            const messageIntervalId = setInterval(async () => {
                const data = await getMessageList(currentWxUser.current.thread_id);
                if (data && data.data) {
                    setMessages(data.data);
                }
            }, 5000);

            return () => clearInterval(messageIntervalId);
        }
    }, [currentWxUser.current]);

    if (loading) {
        return (
            <Space align="center" style={{width: '100%', height: '10 0%', justifyContent: 'center'}}>
                <Spin dot/> {/* 加载动画 */}
            </Space>
        );
    }

    const handleItemClick = async (item) => {
        setMessages([]);
        currentWxUser.current = item;
        const data = await getMessageList(item.thread_id);
        if (data && data.data) {
            setMessages(data.data);
        }
    };

    return (
        <Space align={"start"}>
            <List
                style={{width: 250, height: "92vh", overflowY: "auto"}}
                dataSource={chatList}
                render={(item, index) => (
                    <List.Item key={item.thread_id} style={{
                        backgroundColor: item.thread_id === currentWxUser.current?.thread_id ? '#366EF415' : '',
                        cursor: 'pointer'
                    }}>
                        <List.Item.Meta
                            onClick={() => handleItemClick(item)}
                            avatar={
                                <Badge count={wxUserUnreadList.current[index]}>
                                    <Avatar shape="circle">
                                        <img alt='头像' src={`data:image/png;base64,${item.head_img}`}/>
                                    </Avatar>
                                </Badge>
                            }
                            title={item.nickname}
                            description={truncateText(lastMessages[item.thread_id], 8) || '加载中...'}
                        />
                    </List.Item>
                )}
            />
            <UserChat
                currentWxUser={currentWxUser.current}
                currentWxMyAccount={currentWxAccount}
                messages={messages}
                setMessages={setMessages}
                imageUrls={imageUrls}
                setImageUrls={setImageUrls}
                getMessageList={getMessageList}
            />
        </Space>
    );
};

export default ChatList;