// SalesMaterial.jsx
import {Space} from '@arco-design/web-react';
import React from 'react';
import ProductList from "./components/ProductList";
import CaseMaterial from "./components/CaseMaterial";
import PropTypes from 'prop-types';
import ProductCardModal from "./components/ProductCard";

const SalesMaterial = ({
                           productColumns,
                           caseColumns,
                           productTableStyle,
                           caseTableStyle
                       }) => {
    return (
        <Space direction={"vertical"}>
            <ProductList
                columns={productColumns}
                tableStyle={productTableStyle}
            />
            <ProductCardModal/>
            <CaseMaterial
                columns={caseColumns}
                tableStyle={caseTableStyle}
            />
        </Space>
    )
};

// 定义 propTypes
SalesMaterial.propTypes = {
    productColumns: PropTypes.arrayOf(PropTypes.string),
    caseColumns: PropTypes.arrayOf(PropTypes.string),
    productTableStyle: PropTypes.object,
    caseTableStyle: PropTypes.object
};

export default SalesMaterial;