import React, { useEffect, useState, useCallback } from 'react';
import { Button, Checkbox, Message, Modal, Space, Tag } from '@arco-design/web-react';
import { IconWechat } from '@arco-design/web-react/icon';
import '@arco-design/web-react/dist/css/arco.css';
import { bindAgentApi, getWeChats, loginWechatApi } from '../../../../../api/normalApi';
import { QRCodeSVG } from 'qrcode.react';

const statusColorMap = {
    '未登录': 'blue',
    '登录中': 'orange',
    '已登录': 'green',
};

const LaunchModal = ({ selectedEmployee }) => {
    // 状态声明
    const [visible, setVisible] = useState(false);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [intervalActive, setIntervalActive] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [qrCode, setQrCode] = useState(null);
    const [accountCount, setAccountCount] = useState(1); // 用于生成唯一的账户 ID
    const [isLoggingIn, setIsLoggingIn] = useState(false); // 标识是否有登录正在进行
    const [currentLoggingAccountId, setCurrentLoggingAccountId] = useState(null); // 当前登录的账户 ID
    const [previousAccountCount, setPreviousAccountCount] = useState(0);
    const [previousUpdatedAt, setPreviousUpdatedAt] = useState({}); // 存储每个账号的 updated_at

    // 获取所有微信账户并检测登录状态
    const getAllWxAccounts = useCallback(async () => {
        try {
            const data = await getWeChats();
            console.log('获取到的账户数据:', data.data);

            if (data && data.data) {
                const fetchedAccounts = data.data.map(account => ({
                    id: account.wx_id,
                    label: account.nickname,
                    platform: 'wechat',
                    status: '已登录',
                    statusColor: statusColorMap['已登录'],
                    isNew: false, // 已存在的账户
                    updated_at: account.updated_at, // 确保API返回updated_at
                }));

                setAccounts(prevAccounts => {
                    // 使用Map根据ID合并账户，防止重复
                    const accountMap = new Map();

                    // 添加已登录的账户
                    fetchedAccounts.forEach(acc => {
                        accountMap.set(acc.id, acc);
                    });

                    // 保留所有 isNew: true 的账户（待登录的占位账户）
                    prevAccounts.filter(a => a.isNew).forEach(acc => {
                        accountMap.set(acc.id, acc);
                    });

                    return Array.from(accountMap.values());
                });

                // 检查登录是否成功
                if (isLoggingIn && currentLoggingAccountId) {
                    const currentCount = data.data.length;
                    const previousCount = previousAccountCount;

                    // 判断是否有新账号添加（账号数量增加）
                    if (currentCount > previousCount) {
                        // 登录成功，账号数量增加
                        setIntervalActive(false);
                        setQrCode(null);
                        setIsLoggingIn(false);
                        setCurrentLoggingAccountId(null);
                        setPreviousAccountCount(currentCount);
                        setPreviousUpdatedAt(data.data.reduce((acc, account) => {
                            acc[account.wx_id] = account.updated_at;
                            return acc;
                        }, {}));
                        Message.success('登录成功');
                        return;
                    }

                    // 判断是否有现有账号的 updated_at 变化
                    const updatedAtChanged = data.data.some(account => {
                        const previous = previousUpdatedAt[account.wx_id];
                        return previous && previous !== account.updated_at;
                    });

                    if (updatedAtChanged) {
                        // 登录成功，已有账号的 updated_at 发生变化
                        setIntervalActive(false);
                        setQrCode(null);
                        setIsLoggingIn(false);
                        setCurrentLoggingAccountId(null);
                        setPreviousAccountCount(currentCount);
                        setPreviousUpdatedAt(data.data.reduce((acc, account) => {
                            acc[account.wx_id] = account.updated_at;
                            return acc;
                        }, {}));

                        // 移除所有 isNew: true 的占位账户
                        setAccounts(prevAccounts => prevAccounts.filter(account => !account.isNew));

                        Message.success('登录成功');
                    }
                } else {
                    // 更新前一次的账户列表和计数
                    setPreviousAccountCount(data.data.length);
                    setPreviousUpdatedAt(data.data.reduce((acc, account) => {
                        acc[account.wx_id] = account.updated_at;
                        return acc;
                    }, {}));
                }
            }
        } catch (err) {
            console.log('获取账户失败:', err);
            Message.error('获取账户失败，请稍后重试');
        }
    }, [isLoggingIn, currentLoggingAccountId, previousAccountCount, previousUpdatedAt]);

    // 打开模态框并获取账户
    const handleOpen = useCallback(async () => {
        setVisible(true);
        await getAllWxAccounts();
    }, [getAllWxAccounts]);

    // 关闭模态框并清理相关状态
    const handleClose = useCallback(() => {
        setVisible(false);
        setSelectedAccounts([]);
        setIntervalActive(false); // 停止定时器
        setQrCode(null); // 隐藏二维码
        setIsLoggingIn(false); // 重置登录状态
        setCurrentLoggingAccountId(null); // 重置当前登录账户 ID

        // 过滤掉“未登录”和“登录中”的账户
        setAccounts(prevAccounts => prevAccounts.filter(account => account.status === '已登录'));
        setPreviousAccountCount(0);
        setPreviousUpdatedAt({});
    }, []);

    // 处理账户选择
    const handleAccountChange = useCallback((checkedValues) => {
        setSelectedAccounts(checkedValues);
    }, []);

    // 添加账户并直接启动登录流程
    const addAccounts = useCallback(async () => {
        if (isLoggingIn) {
            Message.warning('登录过程中无法添加新账号');
            return;
        }

        if (accounts.length >= 5) {
            Message.warning('最多只能添加5个账号');
            return;
        }

        const newId = `account${accountCount + 1}`;
        const newAccount = {
            id: newId,
            label: '个人微信',
            platform: 'wechat',
            status: '登录中',
            statusColor: statusColorMap['登录中'],
            isNew: true, // 标识为新添加的账户
        };
        setAccounts(prevState => [...prevState, newAccount]);
        setAccountCount(prevCount => prevCount + 1);

        // 开始登录流程
        setIsLoggingIn(true);
        setCurrentLoggingAccountId(newId);

        try {
            const data = await loginWechatApi();
            if (data && data.data && data.data.qr_code) {
                setQrCode(data.data.qr_code);
                setIntervalActive(true); // 启动轮询
            } else {
                console.log("没有获取到登录数据");
                Message.error('无法获取二维码，请重试');
                // 移除新添加的账户
                setAccounts(prevAccounts => prevAccounts.filter(a => a.id !== newId));
                setIsLoggingIn(false);
                setCurrentLoggingAccountId(null);
            }
        } catch (err) {
            console.log(err);
            Message.error('登录失败，请重试');
            // 移除新添加的账户
            setAccounts(prevAccounts => prevAccounts.filter(a => a.id !== newId));
            setIsLoggingIn(false);
            setCurrentLoggingAccountId(null);
        }
    }, [accounts.length, accountCount, isLoggingIn]);

    // 处理登录点击（用于现有未登录账户）
    const handleLoginClick = useCallback(async (accountId) => {
        if (isLoggingIn) {
            Message.warning('当前已有账号正在登录，请等待完成');
            return;
        }

        setIsLoggingIn(true); // 设置登录状态为进行中
        setCurrentLoggingAccountId(accountId); // 记录当前登录的账户

        setAccounts(prevAccounts => {
            return prevAccounts.map(account => {
                if (account.status === '登录中' && account.id !== accountId) {
                    return {
                        ...account,
                        status: '未登录',
                        statusColor: statusColorMap['未登录'],
                    };
                } else if (account.id === accountId) {
                    return {
                        ...account,
                        status: '登录中',
                        statusColor: statusColorMap['登录中'],
                    };
                }
                return account;
            });
        });

        try {
            const data = await loginWechatApi();
            if (data && data.data && data.data.qr_code) {
                setQrCode(data.data.qr_code);
                setIntervalActive(true);
            } else {
                console.log("没有获取到登录数据");
                Message.error('无法获取二维码，请重试');
                // 恢复状态
                setAccounts(prevAccounts => prevAccounts.map(account => {
                    if (account.id === accountId) {
                        return {
                            ...account,
                            status: '未登录',
                            statusColor: statusColorMap['未登录'],
                        };
                    }
                    return account;
                }));
                setIsLoggingIn(false);
                setCurrentLoggingAccountId(null); // 重置当前登录账户 ID
            }
        } catch (err) {
            console.log(err);
            Message.error('登录失败，请重试');
            // 恢复状态
            setAccounts(prevAccounts => prevAccounts.map(account => {
                if (account.id === accountId) {
                    return {
                        ...account,
                        status: '未登录',
                        statusColor: statusColorMap['未登录'],
                    };
                }
                return account;
            }));
            setIsLoggingIn(false);
            setCurrentLoggingAccountId(null); // 重置当前登录账户 ID
        }
    }, [isLoggingIn]);

    // 取消登录
    const cancelLogin = useCallback((accountId) => {
        const account = accounts.find(a => a.id === accountId);
        if (account && account.isNew) {
            // 删除新添加的占位账户
            setAccounts(prevAccounts => prevAccounts.filter(a => a.id !== accountId));
            // 也从已选择的账户中移除
            setSelectedAccounts(prevSelected => prevSelected.filter(id => id !== accountId));
        } else {
            // 仅重置状态
            setAccounts(prevAccounts => prevAccounts.map(a => {
                if (a.id === accountId) {
                    return { ...a, status: '未登录', statusColor: statusColorMap['未登录'] };
                }
                return a;
            }));
        }
        setIntervalActive(false);
        setQrCode(null);
        setIsLoggingIn(false);
        setCurrentLoggingAccountId(null); // 重置当前登录账户 ID
        setPreviousUpdatedAt(prev => {
            const newMap = { ...prev };
            delete newMap[accountId];
            return newMap;
        });
    }, [accounts]);

    // 绑定代理
    const bindAgent = useCallback(async () => {
        if (selectedAccounts.length > 0) {
            try {
                const data = await bindAgentApi({
                    "wx_user_ids": selectedAccounts,
                    "assistant_id": selectedEmployee.id // TODO: 应该传什么参
                });
                if (data.status === 200){
                    Message.success('绑定成功');
                    handleClose();
                } else {
                    Message.error('绑定失败');
                }
            } catch (err) {
                console.log(err);
                Message.error('绑定失败');
            }
        } else {
            Message.warning("请选择需要发布的账号");
        }
    }, [selectedAccounts, handleClose, selectedEmployee.id]);

    // 设置轮询
    useEffect(() => {
        let intervalId;

        if (intervalActive) {
            intervalId = setInterval(async () => {
                await getAllWxAccounts();
            }, 5000); // 将轮询间隔设置为5秒
        }

        return () => clearInterval(intervalId);
    }, [intervalActive, getAllWxAccounts]);

    // 组件卸载时停止定时器
    useEffect(() => {
        return () => {
            setIntervalActive(false);
        };
    }, []);

    return (
        <Space>
            <Button type='outline' onClick={handleOpen}>
                发布
            </Button>
            <Modal
                visible={visible}
                onCancel={handleClose}
                onOk={handleClose}
                footer={
                    <Space>
                        <Button
                            type="outline"
                            onClick={addAccounts} // '添加账号' 现在触发登录流程
                            disabled={isLoggingIn} // 登录中禁用添加按钮
                        >
                            添加账号
                        </Button>
                        <Button
                            type="primary"
                            onClick={bindAgent}
                            disabled={isLoggingIn} // 登录时禁用发布按钮
                        >
                            发布更新
                        </Button>
                    </Space>
                }
                title={<h3>发布平台</h3>}
                style={{ width: 600, textAlign: 'center' }}
            >
                <Checkbox.Group
                    value={selectedAccounts}
                    onChange={handleAccountChange}
                    style={{ width: '100%'}}
                >
                    {accounts.map((account) => (
                        <div
                            key={account.id}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                                height: 30
                            }}
                        >
                            <Checkbox
                                value={account.id}
                                disabled={account.status !== '已登录'} // 仅允许已登录的账户被选择
                                style={{ marginRight: '10px' }}
                            />
                            <IconWechat style={{ fontSize: 20, color: '#03DB6C', marginRight: '10px' }} />
                            <div
                                style={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <span>{account.label}</span>
                                {account.status === '未登录' || account.status === '登录中' ? (
                                    <Button
                                        type="outline"
                                        size='mini'
                                        onClick={() => handleLoginClick(account.id)}
                                        disabled={isLoggingIn || account.status === '登录中'} // 禁用按钮以防止重复点击和多次登录
                                    >
                                        {account.status === '登录中' ? '登录中' : '登录新账号'}
                                    </Button>
                                ) : (
                                    <Tag color={statusColorMap[account.status]} bordered>
                                        {account.status}
                                    </Tag>
                                )}
                            </div>
                        </div>
                    ))}
                </Checkbox.Group>

                {qrCode && (
                    <Space direction={"vertical"}>
                        <h3>使用微信扫码登录帐号接入</h3>
                        <QRCodeSVG
                            value={qrCode}  // 生成二维码的内容
                            size={150}
                        />
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                const loggingAccount = accounts.find(account => account.status === '登录中');
                                if (loggingAccount) {
                                    cancelLogin(loggingAccount.id);
                                }
                            }}
                            style={{ marginTop: '10px' }}
                        >
                            取消登录
                        </Button>
                    </Space>
                )}
            </Modal>
        </Space>
    );
};

export default LaunchModal;