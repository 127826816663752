import { createRoot } from "react-dom/client";
import { NodeEditor, ClassicPreset } from "rete";
import { AreaPlugin, AreaExtensions } from "rete-area-plugin";
import {
    ConnectionPlugin,
    Presets as ConnectionPresets
} from "rete-connection-plugin";
import { ReactPlugin, Presets, ReactArea2D } from "rete-react-plugin";
import {Input} from "@arco-design/web-react";
const TextArea = Input.TextArea;

// 自定义节点类
class Node extends ClassicPreset.Node {}
class Connection extends ClassicPreset.Connection {}

// 自定义 TextArea 控件类
class TextAreaControl extends ClassicPreset.Control {
    constructor(value, onChange) {
        super();
        this.value = value;
        this.onChange = (e) => {
            console.log(e)
            this.value = e // 直接更新 value
            onChange(this.value);  // 调用传入的回调函数
        };
    }
}

// 自定义 TextArea React 组件
function CustomTextArea(props) {
    return (
        <TextArea
            onChange={props.data.onChange}
            rows={4}
            placeholder="Enter some text"
            style={{ width: "100%" }}
        />
    );
}

export async function createEditor(container) {
    const socket = new ClassicPreset.Socket("socket");

    const editor = new NodeEditor();
    const area = new AreaPlugin(container);
    const connection = new ConnectionPlugin();
    const render = new ReactPlugin({ createRoot });

    render.addPreset(
        Presets.classic.setup({
            customize: {
                control(data) {
                    if (data.payload instanceof TextAreaControl) {
                        return CustomTextArea;
                    }
                    if (data.payload instanceof ClassicPreset.InputControl) {
                        return Presets.classic.Control;
                    }
                    return null;
                }
            }
        })
    );

    connection.addPreset(ConnectionPresets.classic.setup());

    editor.use(area);
    area.use(connection);
    area.use(render);

    const a = new Node("Node with TextArea");
    a.addOutput("a", new ClassicPreset.Output(socket));

    const textAreaControl = new TextAreaControl("Initial text", (value) => {
        console.log("TextArea value changed:", value);
    });

    a.addControl("textarea", textAreaControl);

    await editor.addNode(a);

    AreaExtensions.zoomAt(area, editor.getNodes());

    return {
        destroy: () => area.destroy()
    };
}
