import React, {useEffect, useState} from 'react';
import ChatList from './Messages/ChatList';
import AssistantInfo from './AssistantInfo/AssistantInfo'; // Import the new component
import {Avatar, Space, Tabs} from '@arco-design/web-react';
import {getWxAccountsApi} from "../../api/normalApi";

const Messages = () => {
    const [wxAccountList, setWxAccountList] = useState([]);
    const [currentWxAccount, setCurrentWxAccount] = useState(null);

    const fetchAccounts = async () => {
        try {
            const response = await getWxAccountsApi();
            if (response.status === 200) {
                setWxAccountList(response.data); // Save WeChat account data
                if (response.data.length > 0) {
                    setCurrentWxAccount(response.data[0]); // Default to the first assistant
                }
            }
        } catch (error) {
            console.error("API Error:", error);
        }
    };

    // Fetch WeChat accounts on component mount
    useEffect(() => {
        fetchAccounts();
    }, []);

    const handleTabChange = (v) => {
        const foundAccount = wxAccountList.find(account => account.wx_user_id === v);
        setCurrentWxAccount(foundAccount);
    };

    return (
        <Space align="start">
            <Space style={{width: "60vw",boxShadow: '3px 0 2px rgba(0, 0, 0, 0.03)'}}>
                {wxAccountList.length > 0 && (
                    <Tabs
                        onChange={handleTabChange}
                        type="text"
                        activeTab={currentWxAccount.wx_user_id} // Set the default active tab
                        style={{marginTop: 8}}
                    >
                        {wxAccountList.map((account) => (
                            <Tabs.TabPane key={account.wx_user_id} title={
                                <Space>
                                    <Avatar shape="circle" size={35}>
                                        <img alt='头像' src={`data:image/png;base64,${account.head_img}`}/>
                                    </Avatar>
                                    {account.wx_nickname}
                                </Space>}
                            >
                                {currentWxAccount && currentWxAccount.wx_user_id === account.wx_user_id && (
                                    <ChatList currentWxAccount={currentWxAccount}/>
                                )}
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                )}
            </Space>
            <AssistantInfo/>
        </Space>
    );
};

export default Messages;