import React, { useState } from "react";
import { Space } from "@arco-design/web-react";
import { ListPage } from "./ListPage/ListPage";
import EditPage from "./EditPage/EditPage";
import ChatTest from "./EditPage/ChatTest";

const AgentList = ({ setShowGraph }) => {
    const [chatInstruction, setChatInstruction] = useState(``);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [showEditAgent, setShowEditAgent] = useState(false); // 控制显示部分

    return (
        <Space align={"start"} style={{ height: '100vh' }}>
            {!showEditAgent && (
                <ListPage
                    setSelectedEmployee={(employee) => {
                        setSelectedEmployee(employee);
                        setShowEditAgent(true); // 当选择了员工后显示 EditPage 和 ChatTest
                    }}
                />
            )}

            {showEditAgent && (
                <Space align={"start"} style={{ height: '100vh' }}>
                    <EditPage
                        setChatInstruction={setChatInstruction}
                        chatInstruction={chatInstruction}
                        selectedEmployee={selectedEmployee}
                        setSelectedEmployee={setSelectedEmployee}
                        setShowGraph={setShowGraph}
                        setShowPart={setShowEditAgent} // 可以通过 EditPage 控制返回 ListPage
                    />
                    <ChatTest
                        chatInstruction={chatInstruction}
                        selectedEmployee={selectedEmployee}
                    />
                </Space>
            )}
        </Space>
    );
};

export default AgentList;