// CaseMaterial.jsx
import React, {useEffect, useRef, useState} from 'react';
import {Button, Input, Message, Space, Table, Upload} from '@arco-design/web-react';
import {IconUpload} from '@arco-design/web-react/icon';
import {addCaseApi, getCasesApi, updateCaseApi} from '../../../api/normalApi';
import {uploadFileAPi} from "../../../api/openapi";
import COS from "cos-js-sdk-v5";
import PropTypes from 'prop-types';

const CaseMaterial = ({columns, tableWidth}) => {
    const [caseList, setCaseList] = useState([]);
    const [editableRow, setEditableRow] = useState(null);
    const [isNewRow, setIsNewRow] = useState(false);
    const [addDisabled, setAddDisabled] = useState(false);
    const currentFile = useRef({})
    const storedUsername = localStorage.getItem('user_name');//文件夹名称

    // 获取案例列表
    const getCases = async () => {
        try {
            const {data} = await getCasesApi();
            const casesWithKey = data.map((item, index) => ({
                ...item,
                key: index + 1,
                isNew: false,
            }));
            setCaseList(casesWithKey);
        } catch (error) {
            Message.error('获取案例列表失败，请稍后重试');
        }
    };

    useEffect(() => {
        getCases();
    }, []);

    // 新增案例行
    const addNewCase = () => {
        const newKey = caseList.length + 1;
        const newCase = {
            key: newKey,
            case_name: '',
            file_url: '',
            description: '',
            openai_file_id: '',
            isNew: true,
        };
        setCaseList([...caseList, newCase]);
        setEditableRow(newKey);
        setIsNewRow(true);
        setAddDisabled(true);
        currentFile.current = ''
    };

    // 编辑行
    const handleEdit = (key) => {
        setEditableRow(key);
        setIsNewRow(false);
        currentFile.current = '';
    };

    // 保存case
    const handleSave = async (key) => {
        const caseData = caseList.find((item) => item.key === key);
        if (!caseData.case_name || !caseData.description) {
            Message.error('案例名称和描述不能为空！');
            return;
        }

        //文件上传到openai
        let fileDetail;
        if (currentFile.current) {
            fileDetail = await uploadFileAPi(currentFile.current.originFile);
        }

        //case接口
        const newCase = {
            ...caseData,
            case_name: caseData.case_name,
            openai_file_id: fileDetail ? fileDetail.id : caseData.openai_file_id,
            description: caseData.description,
            file_url: fileDetail ? fileDetail.filename : caseData.file_url
        }

        try {
            if (newCase.isNew) {
                // 新增案例
                const response = await addCaseApi(newCase);
                if (response.status === "success") {
                    await getCases();
                    Message.success('新增案例成功');
                }
            } else {
                // 编辑案例
                const response = await updateCaseApi(newCase.id, newCase);
                if (response.status === "success") {
                    await getCases();
                    Message.success('修改案例成功');
                }
            }
            // 更新案例列表，移除 isNew 标志
            const updatedCaseList = caseList.map((item) =>
                item.key === caseData.key ? {...item, isNew: false} : item
            );
            setCaseList(updatedCaseList);
            setEditableRow(null);
            setAddDisabled(false);
        } catch (error) {
            Message.error('操作失败，请稍后再试');
        }
    };

    // 处理字段变化
    const handleFieldChange = (value, key, field) => {
        const newData = caseList.map((item) => {
            if (item.key === key) {
                return {...item, [field]: value};
            }
            return item;
        });
        setCaseList(newData);
    };

    //上传文件
    const handleFileUpload = async (curFile, key) => {
        const caseData = caseList.find((item) => item.key === key);
        caseData.file_url = `${storedUsername}/${curFile.name}`
        // 使用 map 替换该对象
        const updatedCaseList = caseList.map((item) =>
            item.key === key ? caseData : item
        );
        // 更新状态
        setCaseList(updatedCaseList);
        if (curFile.status === "uploading") {
            //保存文件
            currentFile.current = curFile

            //COS永久密钥上传文件
            const cos = new COS({
                SecretId: 'AKIDxmPF0xDKG1c3JNI1XqwqZ7UggV9ctzym', // 永久密钥
                SecretKey: 'hBSn4XwDKIE5tcGqY8WhfLvKivxtgK3b', // 永久密钥
            });
            cos.putObject(//TODO:把原来上传的对象删除？
                {
                    Bucket: 'zchat-1256349444', // 存储桶名称
                    Region: 'ap-guangzhou',       // 存储桶的区域
                    Key: `${storedUsername}/${curFile.name}`,        // 上传文件名
                    Body: curFile.originFile, // 文件对象
                    // onProgress: function (progressData) {
                    //     console.log('上传进度:', progressData);
                    // },
                },
                function (err, data) {
                    if (err) {
                        console.error('上传出错:', err);
                    } else {
                        Message.success('上传文件成功')
                    }
                }
            );
        }

        // const tempCredentials = await getTempCredentials();//TODO:后端生成安全的用户登录凭证
        // const cos = new COS({//单次历史密钥
        //     SecretId: tempCredentials.TmpSecretId,
        //     SecretKey: tempCredentials.TmpSecretKey,
        //     XCosSecurityToken: tempCredentials.Token,
        //     ExpiredTime: tempCredentials.ExpiredTime,
        // });

    };

    const extractFileName=(filePath)=> {
        if (!filePath) return ''; // 如果路径为空，返回空字符串

        // 使用正则表达式提取最后一个斜杠后面的部分
        return filePath.split('/').pop();
    }

    // 定义所有可能的列
    const allColumns = [
        {
            title: '编号',
            dataIndex: 'key',
            width: 70,
        },
        {
            title: '案例/资料名称',
            dataIndex: 'case_name',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.case_name}
                        onChange={(value) => handleFieldChange(value, record.key, 'case_name')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '案例描述',
            dataIndex: 'description',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.description}
                        onChange={(value) => handleFieldChange(value, record.key, 'description')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '案例文件',
            dataIndex: 'file_url',
            render: (text, record) => (
                <>
                    <a href={text} target="_blank" rel="noopener noreferrer">
                        {text?extractFileName(text) : '暂无文件'}
                    </a>
                    {editableRow === record.key && (
                        <Upload
                            showUploadList={false}
                            onChange={(_, curFile) => handleFileUpload(curFile, record.key)}
                        >
                            <Button type="primary" icon={<IconUpload/>} style={{marginLeft: 8}}>
                                {record.file_url ? '重新上传' : '上传'}
                            </Button>
                        </Upload>
                    )}
                </>
            ),
        },
        {
            title: '操作',
            dataIndex: 'actions',
            width: 80,
            render: (_, record) => {
                if (isNewRow && editableRow === record.key) {
                    return (
                        <Button type="primary" onClick={() => handleSave(record.key)}>
                            保存资料
                        </Button>
                    );
                } else {
                    return editableRow === record.key ? (
                        <Button type="primary" onClick={() => handleSave(record.key)}>
                            保存
                        </Button>
                    ) : (
                        <Button onClick={() => handleEdit(record.key)}>编辑</Button>
                    );
                }
            },
        },
    ];

    // 根据传入的 columns prop 过滤要显示的列，如果未传入，则显示所有列
    const displayedColumns = columns
        ? allColumns.filter(col => columns.includes(col.dataIndex))
        : allColumns;

    return (
        <Space direction='vertical' style={{width: "100%"}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <h3>案例资料</h3>
                <Button
                    type="primary"
                    onClick={addNewCase}
                    disabled={addDisabled}
                >
                    新建资料
                </Button>
            </div>
            <Table
                columns={displayedColumns}
                data={caseList}
                pagination={false}
                rowKey="key"
                style={{minWidth: tableWidth}}
            />
        </Space>
    );
};

// 定义 propTypes
CaseMaterial.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string),
    tableWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default CaseMaterial;