// src/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from "./pages/Login";
import MainLayout from "./pages/MainLayout"; // 新的布局组件
import Messages from "./pages/MessageList/Messages";
import AgentList from "./pages/AgentList/AgentList";
import SalesMaterial from "./pages/SalesMaterial/SalesMaterial";

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem('sales_token');
    return token ? children : <Navigate to="/login" />;
};

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={
                    <PrivateRoute>
                        <MainLayout />
                    </PrivateRoute>
                }>
                    {/* 默认子路由重定向到 /messages */}
                    <Route index element={<Navigate to="/messages" replace />} />
                    <Route path="messages" element={<Messages />} />
                    <Route path="employees" element={<AgentList />} />
                    <Route path="files" element={<SalesMaterial />} />
                    {/* 其他子路由可以在这里添加 */}
                </Route>
                {/* 处理 404 页面 */}
                <Route path="*" element={<Navigate to="/messages" replace />} />
            </Routes>
        </Router>
    );
}

export default AppRouter;