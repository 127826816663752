import React, {useEffect, useRef, useState} from 'react';
import {Input, Button, Space, Tabs, Message} from '@arco-design/web-react';
import SalesProcess from './components/EditPage/SalesProcess';
import SalesMaterial from "../../SalesMaterial/SalesMaterial";
import {updateAssistantApi, getAssistantsIdApi} from "../../../api/normalApi"; // 引入API

const {TabPane} = Tabs;
const {TextArea} = Input;

const EditPage = ({
                      setShowPart,
                      setChatInstruction,
                      chatInstruction,
                      selectedEmployee,
                      setShowGraph
                  }) => {
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState('新员工');
    const inputRef = useRef(null);

    // 新增：获取员工数据并更新状态
    const fetchAssistantData = async (id) => {
        try {
            const response = await getAssistantsIdApi(id);
            if (response.status === 'success') {
                const data = response.data;
                setName(data.name);
                setChatInstruction(data.instructions);
            } else {
                Message.error('获取员工信息失败');
            }
        } catch (error) {
            Message.error('请求失败，请重试');
            console.error(error);
        }
    };

    const handleSaveName = () => {
        updateAssistantApi(selectedEmployee.id, {
            name: name,
        }).then(async data => {
            if (data.status === 'success') {
                Message.success('修改员工名称成功');
            } else {
                Message.error('修改失败，员工名不能为空');
                console.log(data);
            }
        });
        setEditing(false);
    };

    const updateAssistant = async () => {
        const newEmployee = {
            ...selectedEmployee,
            instructions: chatInstruction
        }
        await updateAssistantApi(selectedEmployee.id, newEmployee);
    }

    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    useEffect(() => {
        if (selectedEmployee) {
            fetchAssistantData(selectedEmployee.id); // 获取员工信息
        }
    }, [selectedEmployee]);

    return (
        <Space direction="vertical" style={{height: "100vh", width: "41vw", paddingRight: 8}}>
            <Tabs
                type={'text'}
                defaultActiveTab='1'
                style={{
                    marginTop: 12,
                    width: "100%",
                    height: '97vh',
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#EEE white',
                }}
                extra={
                    <Button size='small' type='text' onClick={() => setShowPart(false)}>
                        返回
                    </Button>
                }
            >
                <TabPane key='1' title='角色信息'>
                    <Input
                        ref={inputRef}
                        value={name}
                        onChange={setName}
                        onBlur={handleSaveName}
                        onPressEnter={handleSaveName}
                        placeholder='未命名的新员工'
                        style={{marginBottom: 10}}
                    />
                    <TextArea
                        placeholder={"填写人物设定"}
                        autoSize={{minRows: 37}}
                        value={chatInstruction}
                        onChange={(v) => {
                            setChatInstruction(v);
                        }}
                        onBlur={() => {
                            updateAssistant().then(r => {})
                        }}
                    />
                </TabPane>
                <TabPane key='2' title='销售资料'>
                    <SalesMaterial
                        productColumns={['key', 'name', 'price', 'actions']}
                        caseColumns={['key', 'case_name', 'description', 'actions']}
                        productTableStyle={{minWidth: "80vw", maxWidth: "90vw"}}
                        caseTableStyle={{minWidth: "70vw", maxWidth: "80vw"}}
                    />
                </TabPane>
                <TabPane key='3' title='销售流程'>
                    <SalesProcess
                        setShowGraph={setShowGraph}
                    />
                </TabPane>
            </Tabs>
        </Space>
    );
};

export default EditPage;